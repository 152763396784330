import { useEffect, useRef, useState } from 'react';
import { getMemes, searchMemes } from '../shared/meme.service';
import Select from 'react-select';
import Chip from '../components/Generic/Chip';
import UserIcon from '../components/Icons/UserIcon';
import PaginationNav from '../components/Generic/PaginationNav';
import { useAppContext } from '../context/AppContext';
import LoadingSpin from '../components/Icons/LoadingSpin';
import SearchIcon from '../components/Icons/SearchIcon';
import { useNavigation } from '../components/Router/NavigationContext';
import Image from '../components/Generic/Image';
import Video from '../components/Generic/Video';
import { MEME_PAGE } from '../constants/routes';
import MemeContent from '../components/Memes/MemeContent';
import { alphabeticallySort } from '../utils';
import MultiValueContainer from '../components/Generic/MultiValueContainer';
import MultiValueRemove from '../components/Generic/MultiValueRemove';
import Link from '../components/Generic/Link';

const filters = [
    { id: 'memes', name: 'Memes' },
    { id: 'actors', name: 'Acteurs' },
];

function SearchResult({ meme, onClick }) {
    const { platformsMap } = useAppContext();

    return (
        <Link href={MEME_PAGE.replace(':id', meme.id)}>
            <div
                className="cursor-pointer rounded-lg border border-gray-100 bg-white p-4 shadow hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
                onClick={onClick}
            >
                <div className="flex">
                    {new URL(meme.url).hostname.match(/video/) ? (
                        <Video
                            properties={{
                                video: {
                                    className:
                                        'rounded-lg object-cover w-full h-full',
                                },
                                playIcon: {
                                    className: 'opacity-75',
                                },
                            }}
                            className="h-40 w-40 rounded-lg"
                            playIcon
                            source={meme.url}
                            type="video/mp4"
                        />
                    ) : (
                        <Image
                            className="h-40 w-40 rounded-lg object-cover"
                            src={meme.url}
                            alt={meme.title}
                        />
                    )}
                    <div className="ms-4">
                        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {meme.title}
                        </h2>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            {platformsMap[meme.platform]}
                        </p>
                        <div className="mt-2 flex flex-wrap gap-2">
                            {meme.actors.map((actor) => (
                                <Chip
                                    key={actor.id}
                                    text={actor.name}
                                    icon={<UserIcon />}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

function MemePopup({ meme, onClose }) {
    const handleClick = () => {
        onClose();
    };

    return (
        <div
            className="flex max-h-[90%] max-w-[90%] flex-col rounded-lg bg-white shadow-lg dark:bg-gray-800"
            style={{ minWidth: 'min(90%, 800px)' }}
        >
            <div className="flex items-center justify-between border-b border-gray-100 p-4 dark:border-gray-700">
                <h2 className="overflow-hidden text-ellipsis whitespace-nowrap text-lg font-semibold text-gray-900 dark:text-white">
                    {meme.title}
                </h2>
                <div className="flex gap-2">
                    <Link
                        href={MEME_PAGE.replace(':id', meme.id)}
                        onClick={handleClick}
                        className="text-nowrap rounded bg-blue-100 px-2 py-1 text-sm text-blue-800 dark:bg-blue-900 dark:text-blue-300"
                    >
                        Ouvrir ce meme
                    </Link>
                    <button
                        className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white"
                        onClick={onClose}
                    >
                        <svg
                            className="h-6 w-6"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="overflow-y-auto">
                <MemeContent meme={meme} />
            </div>
        </div>
    );
}

function SearchPage() {
    const { searchParams } = useNavigation();
    const [filter, setFilter] = useState(filters[0]);
    const [searchFilters, setSearchFilters] = useState({
        actors: [],
    });
    const { actors } = useAppContext();
    const [dropdown, setDropdown] = useState(false);
    const [data, setData] = useState({});
    const [page, setPage] = useState(1);
    const [current, setCurrent] = useState(null);
    const [loading, setLoading] = useState(false);
    const searchInput = useRef(null);
    const resultsContainer = useRef(null);

    const openDropdown = (event) => {
        setDropdown(true);
        const callback = (e) => {
            if (event.target !== e.target) {
                setDropdown(false);
                document.removeEventListener('click', callback);
            }
        };
        document.addEventListener('click', callback);
    };

    const fetchMemes = (search) => {
        setLoading(true);
        if (search?.length >= 3 || searchFilters.actors.length > 0) {
            searchMemes({
                search,
                page,
                actors: searchFilters.actors,
            }).then(({ data }) => {
                setData(data);
                setLoading(false);
            });
        } else {
            getMemes(page).then(({ data }) => {
                setData(data);
                setLoading(false);
            });
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const searchValue = formData.get('search');
        setPage(1);
        setCurrent(null);
        fetchMemes(searchValue);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        resultsContainer.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        fetchMemes();
    }, [page]);

    useEffect(() => {
        setSearchFilters({
            actors: [],
        });
    }, [filter]);

    useEffect(() => {
        if (searchParams.q) {
            fetchMemes(searchParams?.q);
        }
    }, [searchParams.q]);

    useEffect(() => {
        document.body.classList.toggle('overflow-hidden', current !== null);
    }, [current]);

    return (
        <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8">
            <h2 className="mb-10 mt-10 text-center text-4xl font-bold dark:text-white">
                Explorez les memes
            </h2>
            <div
                className={`fixed inset-0 z-50 bg-black bg-opacity-50 backdrop-blur ${
                    current !== null ? '' : 'hidden'
                }`}
            >
                <div className="flex h-full items-center justify-center">
                    {current !== null && (
                        <MemePopup
                            meme={data.results[current]}
                            onClose={() => setCurrent(null)}
                        />
                    )}
                </div>
            </div>
            <form className="mx-auto max-w-lg" onSubmit={handleSearch}>
                <div className="flex">
                    <div className="flex justify-center">
                        <button
                            id="dropdown-button"
                            onClick={openDropdown}
                            data-dropdown-toggle="dropdown"
                            className="z-10 inline-flex flex-shrink-0 items-center whitespace-nowrap rounded-s-lg border border-gray-300 bg-gray-100 px-4 py-2.5 text-center text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                            type="button"
                        >
                            {filter.name}{' '}
                            <svg
                                className="ms-2.5 h-2.5 w-2.5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 4 4 4-4"
                                />
                            </svg>
                        </button>
                        {dropdown && (
                            <div
                                id="dropdown"
                                className="absolute z-10 mt-12 w-44 divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700"
                            >
                                <ul
                                    className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                    aria-labelledby="dropdown-button"
                                >
                                    {filters.map((filter) => (
                                        <li key={filter.id}>
                                            <button
                                                onClick={() =>
                                                    setFilter(filter)
                                                }
                                                type="button"
                                                className="inline-flex w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            >
                                                {filter.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="relative w-full">
                        <input
                            type="search"
                            name="search"
                            defaultValue={searchParams?.q}
                            ref={searchInput}
                            id="search-dropdown"
                            className="z-20 block h-full w-full rounded-e-lg border border-s-2 border-gray-300 border-s-gray-50 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:border-s-gray-700 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500"
                            placeholder="Rechercher dans la catégorie"
                        />
                        <button
                            disabled={loading}
                            type="submit"
                            className={
                                'absolute end-0 top-0 h-full rounded-e-lg border border-blue-700 bg-blue-700 p-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ' +
                                (loading ? 'cursor-not-allowed' : '')
                            }
                        >
                            {loading ? <LoadingSpin /> : <SearchIcon />}
                            <span className="sr-only">Rechercher</span>
                        </button>
                    </div>
                </div>
            </form>
            {filter?.id === 'actors' && (
                <div className="mt-8 flex justify-center">
                    <Select
                        isMulti={true}
                        closeMenuOnSelect={false}
                        maxLength={3}
                        options={actors
                            .map((actor) => ({
                                value: actor.id,
                                label: actor.name,
                            }))
                            .toSorted(alphabeticallySort('label'))}
                        placeholder="Séléctionner les acteurs"
                        className="my-react-select-container mb-2 block w-full max-w-lg text-sm font-medium text-gray-900 dark:text-white"
                        classNamePrefix="my-react-select"
                        components={{
                            MultiValueContainer: MultiValueContainer,
                            MultiValueLabel: (props) => null,
                            MultiValueRemove: MultiValueRemove,
                        }}
                        isOptionDisabled={() =>
                            searchFilters.actors.length >= 3
                        }
                        onChange={(actors) =>
                            setSearchFilters({
                                ...searchFilters,
                                actors: actors.map((actor) =>
                                    Number(actor.value),
                                ),
                            })
                        }
                    />
                </div>
            )}
            <div
                className="mt-8 flex grid-cols-1 flex-col flex-wrap gap-4 lg:grid lg:grid-cols-3"
                ref={resultsContainer}
            >
                {data?.count >= 0 && (
                    <div className="col-span-3 flex justify-center">
                        <p className="text-gray-500 dark:text-gray-400">
                            {data.count > 0
                                ? `${data.count} résultat${data.count > 1 ? 's' : ''} trouvé${data.count > 1 ? 's' : ''}`
                                : 'Aucun résultat trouvé'}
                        </p>
                    </div>
                )}
                {data?.results?.map((meme, index) => (
                    <SearchResult
                        key={`meme-${meme.id}-${index}`}
                        meme={meme}
                    />
                ))}
            </div>
            <div className="mt-8 flex justify-center">
                {data?.count > 0 && (
                    <PaginationNav
                        next={data.next}
                        previous={data.previous}
                        count={data.count}
                        current={page}
                        onChange={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
}

export default SearchPage;
