import { LOGIN_FORM } from './constants/routes';

export const isArrayOfStrings = (value) => {
    if (!value) return false;
    try {
        const result = JSON.parse(value);
        if (!Array.isArray(result)) return false;
        for (const item of result) {
            if (typeof item !== 'string') return false;
        }
        return true;
    } catch (e) {
        return false;
    }
};

export const isNonEmptyArrayOfIntegers = (value) => {
    if (!value) return false;
    try {
        const result = JSON.parse(value);
        if (!Array.isArray(result)) return false;
        return (
            result.length > 0 && result.every((item) => Number.isInteger(item))
        );
    } catch (e) {
        return false;
    }
};

export const alphabeticallySort = (key) => (a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
};

export const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) window.location.href = LOGIN_FORM;
    return { Authorization: `Token ${token}` };
};

export const isDarkModeEnabled = () => {
    return localStorage.getItem('dark') === 'true';
};

export const setDarkModeEnabled = (value) => {
    localStorage.setItem('dark', value);
    document.documentElement.classList.toggle('dark', value);
};

export const isMobile = () => {
    return window.innerWidth <= 768;
};

export const isExternalLink = (url) => {
    if (!url.startsWith('http')) return false;
    const urlObject = new URL(url);
    return urlObject.hostname !== window.location.hostname;
};
