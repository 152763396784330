import { useEffect, useState } from 'react';
import { useNavigation } from '../components/Router/NavigationContext';
import { getMeme } from '../shared/meme.service';
import LoadingSpin from '../components/Icons/LoadingSpin';
import Video from '../components/Generic/Video';
import Image from '../components/Generic/Image';
import Chip from '../components/Generic/Chip';
import UserIcon from '../components/Icons/UserIcon';
import PlatformIcon from '../components/Icons/PlatformIcon';
import { useAppContext } from '../context/AppContext';
import ShareButton from '../components/Generic/ShareButton';
import Link from '../components/Generic/Link';
import { NOT_FOUND_PAGE, EXPLORE_PAGE } from '../constants/routes';
function MemePage() {
    const { params } = useNavigation();
    const { platformsMap } = useAppContext();
    const { navigate } = useNavigation();
    const [meme, setMeme] = useState(null);

    useEffect(() => {
        if (params.id) {
            getMeme(params.id).then((response) => {
                if (response.ok) {
                    setMeme(response.data);
                    document.title = response.data.title + ' - Mouldahka';
                } else {
                    navigate(NOT_FOUND_PAGE);
                }
            });
        }
    }, [params.id]);

    return meme ? (
        <div className="container mx-auto">
            <h3 className="mb-4 mt-10 px-5 text-center text-3xl font-bold dark:text-white">
                {meme.title}
            </h3>
            <div className="mx-auto max-w-3xl">
                <div className="p-4">
                    <div className="flex items-center justify-center">
                        {new URL(meme.url).hostname.match(/video/) ? (
                            <Video
                                className="flex-1 rounded-lg bg-gray-950 dark:bg-transparent"
                                properties={{
                                    video: {
                                        className:
                                            'w-full max-h-96 rounded-lg object-cover',
                                        controls: true,
                                        autoPlay: true,
                                        loop: true,
                                    },
                                    ambilight: {
                                        className: 'rounded-lg',
                                    },
                                }}
                                source={meme.url}
                                type="video/mp4"
                                ambilight
                            />
                        ) : (
                            <Image
                                className="max-h-96 max-w-full flex-1 rounded-lg object-cover"
                                src={meme.url}
                                alt={meme.title}
                                allowFullScreen={true}
                            />
                        )}
                    </div>
                    <div className="mt-2 flex justify-between">
                        <div className="flex flex-wrap gap-2">
                            {meme.actors.map((actor) => (
                                <Chip
                                    key={actor.id}
                                    text={actor.name}
                                    icon={<UserIcon />}
                                />
                            ))}
                            <Chip key="2" text={platformsMap[meme.platform]} icon={<PlatformIcon/>} color="emerald" />
                        </div>
                        <ShareButton
                            title={meme.title}
                            url={meme.share_url}
                            text={meme.actors
                                .map((actor) => actor.name)
                                .join(', ')}
                        />
                    </div>
                </div>
                {!['', ' '].includes(meme.words[0]) && (
                    <div className="p-4">
                        <div className="max-w-prose text-sm font-medium dark:text-white">
                            Paroles :{' '}
                            <ul className="ml-5 mt-2 list-['-_'] font-normal italic text-gray-500 dark:text-gray-400">
                                {meme.words.map((word, index) => (
                                    <li key={`${word}-${index}`}>{word}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                <div className="p-4">
                    <div className="flex flex-wrap items-center gap-2 text-sm font-medium dark:text-white">
                        Tags :{' '}
                        <div className="flex flex-wrap gap-1">
                            {meme.tags.map((tag, index) => (
                                <Link key={index} href={EXPLORE_PAGE} params={{'q':tag}}><Chip key={index} text={tag} color="gray" clickable="true" /></Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="flex h-screen w-full items-center justify-center">
            <LoadingSpin className="h-6 w-6" />
        </div>
    );
}

export default MemePage;
